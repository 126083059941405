<template>
  <div v-if="loaded">
    <b-row>
      <b-col>
        <b-card-group deck>
          <b-card :bg-variant="(!units.available || units.available === 0) ? 'warning' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings/book'});">
            <b-card-title>{{ units.available }}/{{ units.total }}</b-card-title>
            <b-card-text>Available units</b-card-text>
          </b-card>
          <b-card :bg-variant="(!bookings.active || bookings.active === 0) ? 'danger' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings', query: { status: 'ACTIVE' }});">
            <b-card-title>{{ bookings.active }}</b-card-title>
            <b-card-text>Active Bookings</b-card-text>
          </b-card>
          <b-card :bg-variant="(bookings.overdue && bookings.overdue > 0) ? 'danger' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings', query: { status: 'ACTIVE' }});">
            <b-card-title>{{ bookings.overdue }}</b-card-title>
            <b-card-text>Overdue Bookings</b-card-text>
          </b-card>
          <b-card :bg-variant="(bookings.pending && bookings.pending > 0) ? 'danger' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings', query: { status: 'NEW' }});">
            <b-card-title>{{ bookings.pending }}</b-card-title>
            <b-card-text>Pending Bookings</b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <DashboardServiceOrders />
      </b-col>
      <b-col>
        <b-card-group deck>
          <DashboardBalance />
          <b-card :bg-variant="(jobs.error && jobs.error > 0) ? 'danger' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/tasks/jobs', query: { status: 'ERROR' }});">
            <b-card-title>{{ jobs.error }}</b-card-title>
            <b-card-text>Failured jobs</b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  opacity: .9;
}
.card:hover {
  opacity: 1;
}
.col {
  margin-bottom: 1rem;
}
</style>

<script>
import DashboardServiceOrders from '../../../service-modules/services/components/dashboard-serviceorders.vue';
import DashboardBalance from '../../../service-modules/invoices/components/dashboard-balance.vue';

export default {
  components: {
    DashboardBalance,
    DashboardServiceOrders,
  },
  data () {
    return {
      loaded: false,
      jobs: { count: 0, total: 0 },
      bookings: { active: 0, pending: 0, overdue: 0 },
      units: { available: 0, total: 0 },
    };
  },
  async created () {
    await Promise.all([
      this.getJobsSummary(),
      this.getBookingsSummary(),
      this.getUnitsSummary(),
    ]);
    this.loaded = true;
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    getJobsSummary () {
      return this.$http.get('summary/jobs')
        .then((res) => {
          if (res && res.data) {
            this.jobs = res.data;
          }
        })
        .catch(this.err);
    },
    getBookingsSummary () {
      return this.$http.get('summary/bookings')
        .then((res) => {
          if (res && res.data) {
            this.bookings = res.data;
          }
        })
        .catch(this.err);
    },
    getUnitsSummary () {
      return this.$http.get('summary/units')
        .then((res) => {
          if (res && res.data) {
            this.units = res.data;
          }
        })
        .catch(this.err);
    },

  },
};
</script>
