<template>
  <b-card v-if="balance && modules && !!modules['invoices']" :bg-variant="(balance && balance.balance < 0) ? 'danger' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings/balances' });">
    <b-card-title>{{ balance.balance }}</b-card-title>
    <b-card-text>Current balance</b-card-text>
  </b-card>
</template>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  opacity: .9;
}
.card:hover {
  opacity: 1;
}
</style>

<script>
export default {
  data () {
    return {
      balance: null
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  created () {
    this.getBalanceSummary();
  },
  methods: {
    getBalanceSummary () {
      return this.$http.get('summary/balance')
        .then(({ data }) => {
          this.balance = data;
        })
        .catch(this.err);
    },
  },
};
</script>
