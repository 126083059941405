<template>
  <b-card-group v-if="modules && !!modules['services']" deck>
    <b-card :bg-variant="(serviceorders.pending && serviceorders.pending > 0) ? 'danger' : 'success'" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings/serviceorders', query: { status: 'NEW' }});">
      <b-card-title>{{ serviceorders.pending }}</b-card-title>
      <b-card-text>Pending service orders</b-card-text>
    </b-card>
    <b-card bg-variant="success" text-variant="white" class="text-center" @click="$router.push({ path: '/app/bookings/serviceorders', query: { status: 'ACTIVATED' }});">
      <b-card-title>{{ serviceorders.active }}</b-card-title>
      <b-card-text>Active service orders</b-card-text>
    </b-card>
  </b-card-group>
</template>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  opacity: .9;
}
.card:hover {
  opacity: 1;
}
</style>

<script>
export default {
  data () {
    return {
      serviceorders: { active: 0, pending: 0 },
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  created () {
    // @IDEA: loading dashboard component
    this.getServiceOrdersSummary();
  },
  methods: {
    getServiceOrdersSummary () {
      return this.$http.get('summary/serviceorders')
        .then((res) => {
          if (res && res.data) {
            this.serviceorders = res.data;
          }
        })
        .catch(this.err);
    },
  },
};
</script>
